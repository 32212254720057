@import "../../css/constants.scss";
.history_gh_element {
  opacity: 0;
  display: inline-block;
  .history_icon {
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 3px;
      border: 1px solid #DFDFDF;
      cursor: pointer;
      span {
          font-size: 10px;
          color: #BBBCC5;
          font-weight: bold;
          &.active {
              color: $blue;
          }
      }
  }
  .history_wrapper {
      box-shadow: 0px 1px 30px 2px #57575740;
      background: #fff;
      max-width: 270px;
      width: 100%;
      padding: 16px;
      max-height: 205px;
      overflow-y: auto;
      border-radius: 12px;
      position: absolute;
      z-index: 100;
      word-wrap: break-word;

      .item {
        position: relative;
          &:not(:last-child) {
              margin-bottom: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid #EAEAEA;
          }
          .updated_value {
              margin-top: 6px;
              color: #9A9BA5;
              text-align: left;
              font-size: 14px;
              font-weight: 300;
              .new_value {
                  font-weight: bold!important;
                  color: $black;
                  font-style: italic;
              }
          }
          .history_user {
            .photo {
                border-radius: 50%;
                height: 40px;
                margin: 3px 0;
            }
            .name {
                padding-top: 5px;
                font-size: 14px;
                font-weight: 500;
                line-height: 1;
                color: $black;
            }
          }
          .date {
            position: absolute;
            left: 45px;
            top: 23px;
          }
      }

      .title {
          font-size: 14px;
          font-weight: 500;
          color: $blue;
          margin-bottom: 14px;
          text-align: left;
      }
  }
}